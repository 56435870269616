{
  "PerformaasDev-Stateful": {
    "CognitoIdentityPoolId": "eu-central-2:dea50a16-dfe5-4a8a-bd64-c5c30adbf2ef",
    "ExportsOutputRefStatefulConstructAccountHostedZoneD7DB66D763C03660": "Z08471871G99MY0G7WRDB",
    "ExportsOutputFnGetAttStatefulConstructPrivateBucketBCF14759Arn35AF6995": "arn:aws:s3:::performaasdev-stateful-statefulconstructprivatebuc-nii4pxseumpd",
    "ExportsOutputRefStatefulConstructFifoQueueF2D2AADE53911972": "https://sqs.eu-central-2.amazonaws.com/275214716993/PerformaasDev-Stateful-StatefulConstructFifoQueueF2D2AADE-K6JV2idB69Fb.fifo",
    "ExportsOutputFnGetAttStatefulConstructStandardQueue76384806Arn5AA78012": "arn:aws:sqs:eu-central-2:275214716993:PerformaasDev-Stateful-StatefulConstructStandardQueue76384806-Tb41g48foTT1",
    "ExportsOutputRefStatefulConstructStandardQueue763848066038EC6E": "https://sqs.eu-central-2.amazonaws.com/275214716993/PerformaasDev-Stateful-StatefulConstructStandardQueue76384806-Tb41g48foTT1",
    "ExportsOutputRefStatefulConstructMainTableFD1A6753DAE28D47": "PerformaasDev-Stateful-StatefulConstructMainTableFD1A6753-EEDHYQH654NE",
    "ExportsOutputFnGetAttStatefulConstructFifoQueueF2D2AADEArn62F57126": "arn:aws:sqs:eu-central-2:275214716993:PerformaasDev-Stateful-StatefulConstructFifoQueueF2D2AADE-K6JV2idB69Fb.fifo",
    "ExportsOutputFnGetAttStatefulConstructMainTableFD1A6753Arn88C989DE": "arn:aws:dynamodb:eu-central-2:275214716993:table/PerformaasDev-Stateful-StatefulConstructMainTableFD1A6753-EEDHYQH654NE",
    "CognitoUserPoolWebClientId": "4kc1gbgu87jivtjhgu1bkn8ov5",
    "ExportsOutputRefStatefulConstructPrivateBucketBCF1475949921C72": "performaasdev-stateful-statefulconstructprivatebuc-nii4pxseumpd",
    "CognitoUserPoolId": "eu-central-2_xhZfapbTS",
    "AwsAccount": "275214716993",
    "Region": "eu-central-2"
  },
  "PerformaasDev-Stateles": {
    "ApiConstructRestApiEndpoint4558A492": "https://cfypsbrn22.execute-api.eu-central-2.amazonaws.com/prod/"
  }
}
