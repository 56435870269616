import { Routes } from '@angular/router';
import { authGuard } from '@yeekatee/auth-data-access';
import { NavigationRouteNames } from '@yeekatee/shared-util-routes';

export const appRoutes: Routes = [
  {
    path: NavigationRouteNames.HOME,
    canActivateChild: [authGuard],
    loadChildren: () =>
      import('./pages/report/report.routes').then((module) => module.routes),
  },
  {
    path: NavigationRouteNames.AUTH,
    loadChildren: () =>
      import('@yeekatee/auth-feature-authenticator').then(
        (module) => module.authRoutes,
      ),
  },
  {
    path: '',
    redirectTo: NavigationRouteNames.HOME,
    pathMatch: 'full',
  },
];
