<ion-app *ngrxLet="vm$ as vm">
  <ion-split-pane
    when="xl"
    contentId="main-content"
    (ionSplitPaneVisible)="onSplitPaneVisible($event)"
  >
    <ion-menu contentId="main-content" [swipeGesture]="vm.isWebThemeActive">
      @if (vm.isAuthenticated) {
        <ion-header class="ion-no-border">
          <ion-toolbar>
            <ion-button slot="start" fill="clear">
              <yeekatee-logo
                slot="icon-only"
                cssClass="w-40 h-8 align-middle ion-margin-horizontal"
              />
            </ion-button>
          </ion-toolbar>
        </ion-header>
      }
    </ion-menu>
    <ion-router-outlet
      class="xl:border xl:border-slate-200 xl:dark:border-slate-800"
      id="main-content"
    />
    <ion-menu
      side="end"
      contentId="main-content"
      [swipeGesture]="vm.isWebThemeActive && vm.isAuthenticated"
    >
      @if (vm.isAuthenticated) {
        <ion-header class="ion-no-border">
          <ion-toolbar>
            <ion-buttons slot="end">
              <ion-button (click)="signOut()">
                <ion-icon slot="end" color="primary" name="log-out-outline" />
                <ion-label i18n>Log out</ion-label>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
      }
    </ion-menu>
  </ion-split-pane>
</ion-app>
