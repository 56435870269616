import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  LOCALE_ID,
  isDevMode,
} from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withPreloading,
} from '@angular/router';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { provideEffects } from '@ngrx/effects';
import {
  NavigationActionTiming,
  RouterState,
  provideRouterStore,
  routerReducer,
} from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { AuthEffects } from '@yeekatee/auth-data-access';
import { STATE_PROVIDERS, ThemeEffects } from '@yeekatee/core-data-access';
import { LocalesEffects, LocalesService } from '@yeekatee/i18n/data-access';
import { ENVIRONMENT } from '@yeekatee/shared-util-environment';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { sigv4Interceptor } from './services/sigv4.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideStore(),
    provideState({ name: 'router', reducer: routerReducer }),
    provideRouterStore({
      routerState: RouterState.Minimal,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    ...STATE_PROVIDERS,
    provideEffects(AuthEffects, LocalesEffects, ThemeEffects),
    provideRouter(
      appRoutes,
      withPreloading(PreloadAllModules),
      withComponentInputBinding(),
    ),
    provideHttpClient(withFetch(), withInterceptors([sigv4Interceptor])),
    {
      provide: APP_INITIALIZER,
      deps: [LocalesService],
      useFactory: (service: LocalesService) => () =>
        service.initializeTranslation(),
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [LocalesService],
      useFactory: (service: LocalesService) => service.locale,
      // ! This value service.locale was set above, in the async APP_INITIALIZER
    },
    { provide: ENVIRONMENT, useValue: environment },
    provideIonicAngular(),
    provideCharts(withDefaultRegisterables()),
    provideStoreDevtools({ logOnly: !isDevMode(), autoPause: true }),
  ],
};
