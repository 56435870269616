import { Component } from '@angular/core';
import {
  IonApp,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  IonToolbar,
} from '@ionic/angular/standalone';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import {
  AuthEffectsActions,
  ThemeActions,
  ViewModelSelectors,
} from '@yeekatee/core-data-access';
import { LogoComponent } from '@yeekatee/shared-ui';
import { addIcons } from 'ionicons';
import { logOutOutline } from 'ionicons/icons';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    IonApp,
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonLabel,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    IonToolbar,
    LetDirective,
    LogoComponent,
  ],
  templateUrl: './app.component.html',
})
export class AppComponent {
  protected readonly vm$ = this.store.select(
    ViewModelSelectors.selectAppViewModel,
  );

  constructor(private readonly store: Store) {
    addIcons({ logOutOutline });
  }

  protected onSplitPaneVisible(event: CustomEvent) {
    this.store.dispatch(
      ThemeActions.changeWebTheme({
        isActive: event.detail.visible ?? false,
      }),
    );
  }

  protected signOut() {
    this.store.dispatch(AuthEffectsActions.signOutCleanupSuccess());
  }
}
