// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// ! Deploy the stack in your personal AWS Account using `nx deploy` to use this
import { isDevMode } from '@angular/core';
import cdk_output from '../../../smart-app/src/environments/cdk.output.json';
import { Environment, defineEnvironment } from '../app/models/environment';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

const stack = cdk_output['PerformaasDev-Stateful'];

export const environment: Environment = defineEnvironment(stack, {
  production: !isDevMode(),
});
